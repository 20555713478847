import React from 'react';
import SwapPage from './components/SwapPage';


import '@solana/wallet-adapter-react-ui/styles.css';
function App() {
  return (
    <div className="App">
      <SwapPage />
    </div>
  );
}

export default App;
